import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import Header from '../components/header'
import Footer from '../components/footer'
import facebooklogo from '../assets/images/icon/facebook.svg'
import linkedinlogo from '../assets/images/icon/linkedin.svg'
import knsicongrey from '../assets/images/icon/kns-icon-grey.svg'
import knsblue from '../assets/images/logo/kns-blue.svg'
import arrow from '../assets/images/icon/arrow-link.svg'
import back from '../assets/images/icon/back.svg'
import cmd from '../assets/images/icon/content-management-dark.svg'
import reporting from '../assets/images/icon/reporting.svg'
import monitoring from '../assets/images/icon/monitoring.svg'
import software from '../assets/images/icon/sofware.svg'
import contentmanagement from '../assets/images/icon/content-management.svg'
import hosting from '../assets/images/icon/hosting.svg'
import technical from '../assets/images/icon/technical.svg'

import reportingDark from '../assets/images/icon/reporting-dark.svg'
import monitoringDark from '../assets/images/icon/monitoring-dark.svg'
import softwareDark from '../assets/images/icon/sofware-dark.svg'
import hostingDark from '../assets/images/icon/hosting-dark.svg'
import technicalDark from '../assets/images/icon/technical-dark.svg'
import $ from 'jquery'
import LocalizedLink from '../LocalizedLink'

class SupportPage extends React.Component {
  componentDidMount() {
    if (window.location.pathname === '/supportPage') {
      $('.logo a img').attr('src', knsblue)
      $('.menu ul li a').css('color', '#1b437b')
      $('.header').css('background', '#ffffff')
      $('.language').css('border-color', '#1b437b')
      $('.language a span').css('color', '#1b437b')
      $('.other-page-social').show(100)
      $('#nav-toggle span').addClass('colorline')
      $('.numbers h1,.numbers h4').css('color', '#1b437b')
    }

    $('.mb-nav-btn').click(function() {
      $('.mobile-menu').fadeIn(200)
    })
    $('.close').click(function() {
      $('.mobile-menu').fadeOut(200)
    })

    $('.mobile-menu ul li a').click(function() {
      $('.mobile-menu').fadeOut(200)
    })

    $('.support').click(function() {
      $('.supports').fadeOut(200)
      $('.support-content').fadeIn(200)
      $('.support-technical').fadeOut(200)
      $('.support-hosting').fadeOut(200)
      $('.support-reporting').fadeOut(200)
      $('.support-update').fadeOut(200)
      $('.support-monitoring').fadeOut(200)
    })
    $('.supporthosting').click(function() {
      $('.supports').fadeOut(200)
      $('.support-content').fadeOut(200)
      $('.support-technical').fadeOut(200)
      $('.support-hosting').fadeIn(200)
      $('.support-reporting').fadeOut(200)
      $('.support-update').fadeOut(200)
      $('.support-monitoring').fadeOut(200)
    })
    $('.supporttechnical').click(function() {
      $('.supports').fadeOut(200)
      $('.support-content').fadeOut(200)
      $('.support-technical').fadeIn(200)
      $('.support-hosting').fadeOut(200)
      $('.support-reporting').fadeOut(200)
      $('.support-update').fadeOut(200)
      $('.support-monitoring').fadeOut(200)
    })
    $('.supportreporting').click(function() {
      $('.supports').fadeOut(200)
      $('.support-content').fadeOut(200)
      $('.support-technical').fadeOut(200)
      $('.support-hosting').fadeOut(200)
      $('.support-reporting').fadeIn(200)
      $('.support-update').fadeOut(200)
      $('.support-monitoring').fadeOut(200)
    })
    $('.supportmonitoring').click(function() {
      $('.supports').fadeOut(200)
      $('.support-monitoring').fadeIn(200)
      $('.support-content').fadeOut(200)
      $('.support-technical').fadeOut(200)
      $('.support-hosting').fadeOut(200)
      $('.support-reporting').fadeOut(200)
      $('.support-update').fadeOut(200)
    })
    $('.supportupdate').click(function() {
      $('.supports').fadeOut(200)
      $('.support-update').fadeIn(200)
      $('.support-content').fadeOut(200)
      $('.support-technical').fadeOut(200)
      $('.support-hosting').fadeOut(200)
      $('.support-reporting').fadeOut(200)
      $('.support-monitoring').fadeOut(200)
    })
    $('.back').click(function() {
      $('.support-update').fadeOut(200)
      $('.support-content').fadeOut(200)
      $('.support-technical').fadeOut(200)
      $('.support-hosting').fadeOut(200)
      $('.support-reporting').fadeOut(200)
      $('.support-monitoring').fadeOut(200)
      $('.supports').fadeIn(200)
    })

    $('.mb-nav-btn').click(function() {
      $('.mobile-menu').fadeIn(200)
    })
    $('.close').click(function() {
      $('.mobile-menu').fadeOut(200)
    })

    $('.mobile-menu ul li a').click(function() {
      $('.mobile-menu').fadeOut(200)
    })
  }
  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <body className="other-page">
          <header className="header">
            <div className="row">
              <div className="col-3 logo">
                <a href="/">
                  <img
                    src={require('../assets/images/logo/kns.svg')}
                    alt="KNS"
                    title="KNS"
                  />
                </a>
              </div>
              <div className="col-9">
                {/*<div href="#" className="language">*/}

                {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

                {/*</div>*/}
                <a href="#" id="nav-toggle" class="mb-nav-btn">
                  <span />
                </a>
                <nav className="menu">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          <div className="mobile-menu">
            <div className="close">
              <img src={require('../assets/images/icon/close-nav.svg')} />
            </div>

            <nav className="mb-nav">
              <ul>
                <li>
                  <LocalizedLink to="/aboutPage">
                    <FormattedMessage id="about" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platformsPage">
                    <FormattedMessage id="platforms" />
                  </LocalizedLink>
                </li>
                <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                <li>
                  <LocalizedLink to="/supportPage">
                    <FormattedMessage id="supports" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/clientsPage">
                    <FormattedMessage id="clients" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platforms/venuex">
                    <FormattedMessage id="Venuex" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/careerPage">
                    <FormattedMessage id="Career" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/contactPage">
                    <FormattedMessage id="Contact" />
                  </LocalizedLink>
                </li>
              </ul>
            </nav>
          </div>

          <article className="home-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>
          <section className="page">
            <div className="container">
              <div className="col-12">
                <figure className="kns-grey-icon">
                  <img src={knsicongrey} />
                </figure>

                <div className="page-prp">
                  <h1>
                    <FormattedMessage id="supports" />
                  </h1>
                  {this.props.pageContext.locale == 'tr' && (
                    <>
                      <p>
                        Ensure a smooth running, fast and effective business
                        with the help of a 24/7 Support Team.
                      </p>
                    </>
                  )}
                  {this.props.pageContext.locale == 'en' && (
                    <>
                      <p>
                        Ensure a smooth running, fast and effective business
                        with the help of a 24/7 Support Team.
                      </p>
                    </>
                  )}
                </div>

                <section className="supports">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-center">
                        <a className="support">
                          <div className="detail-link">
                            <img src={arrow} />
                            <span>LEARN MORE</span>
                          </div>
                          <div className="circle-one" />
                          <div className="circle-two" />
                          <div className="support-prp">
                            <img src={contentmanagement} />
                            <h1>Content Management</h1>
                          </div>
                        </a>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-center">
                        <a className="supporthosting">
                          <div className="detail-link">
                            <img src={arrow} />
                            <span>LEARN MORE</span>
                          </div>
                          <div className="circle-one" />
                          <div className="circle-two" />
                          <div className="supporthosting-prp">
                            <img src={hosting} />
                            <h1>Hosting</h1>
                          </div>
                        </a>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-center">
                        <a className="supporttechnical">
                          <div className="detail-link">
                            <img src={arrow} />
                            <span>LEARN MORE</span>
                          </div>
                          <div className="circle-one" />
                          <div className="circle-two" />
                          <div className="support-prp">
                            <img src={technical} />
                            <h1>Technical Maintenance</h1>
                          </div>
                        </a>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-center">
                        <a className="supportreporting">
                          <div className="detail-link">
                            <img src={arrow} />
                            <span>LEARN MORE</span>
                          </div>
                          <div className="circle-one" />
                          <div className="circle-two" />
                          <div className="supportreporting-prp">
                            <img src={reporting} />
                            <h1>Reporting</h1>
                          </div>
                        </a>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-center">
                        <a className="supportmonitoring">
                          <div className="detail-link">
                            <img src={arrow} />
                            <span>LEARN MORE</span>
                          </div>
                          <div className="circle-one" />
                          <div className="circle-two" />
                          <div className="supportmonitoring-prp">
                            <img src={monitoring} />
                            <h1>Monitoring</h1>
                          </div>
                        </a>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-center">
                        <a className="supportupdate">
                          <div className="detail-link">
                            <img src={arrow} />
                            <span>LEARN MORE</span>
                          </div>
                          <div className="circle-one" />
                          <div className="circle-two" />
                          <div className="supportupdate-prp">
                            <img src={software} />
                            <h1>Software Update</h1>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="support-details">
                  {/*  Support / ContentManagement   */}

                  <section className="support-content text-center">
                    <div className="container ps-relative">
                      <a className="back">
                        <img src={back} />
                        <span>Back</span>
                      </a>

                      <div>
                        <img src={cmd} className="support-icon-size" />
                      </div>

                      <h1>Content Management</h1>
                      {this.props.pageContext.locale == 'tr' && (
                        <>
                          <p>
                            The more shopper engagement channels you run, the
                            more it gets important to keep the consistency.
                            Shoppers nowadays are more demanding on receiving
                            continuous and seamless experience information
                            throughout communication channels.{' '}
                          </p>

                          <p>
                            We will be monitoring your multiple media streams
                            and updating real time content in order to keep you
                            on track through your business goals and meanwhile
                            saving you time.
                          </p>

                          <ul>
                            <li>Specialized Content Management Team </li>
                            <li>Fast and Efficient Real Time Updating </li>
                            <li>Personalized Monitoring </li>
                            <li>Effective Time Management</li>
                          </ul>
                        </>
                      )}

                      {this.props.pageContext.locale == 'en' && (
                        <>
                          <p>
                            The more shopper engagement channels you run, the
                            more it gets important to keep the consistency.
                            Shoppers nowadays are more demanding on receiving
                            continuous and seamless experience information
                            throughout communication channels.{' '}
                          </p>

                          <p>
                            We will be monitoring your multiple media streams
                            and updating real time content in order to keep you
                            on track through your business goals and meanwhile
                            saving you time.
                          </p>

                          <ul>
                            <li>Specialized Content Management Team </li>
                            <li>Fast and Efficient Real Time Updating </li>
                            <li>Personalized Monitoring </li>
                            <li>Effective Time Management</li>
                          </ul>
                        </>
                      )}
                    </div>
                  </section>

                  {/*  Support / Hosting   */}

                  <section className="support-hosting text-center">
                    <div className="container ps-relative">
                      <a className="back">
                        <img src={back} />
                        <span>Back</span>
                      </a>

                      <div>
                        <img src={hostingDark} className="support-icon-size" />
                      </div>

                      <h1>Hosting</h1>
                      {this.props.pageContext.locale == 'tr' && (
                        <>
                          <p>
                            Hosting services ensures your Digital Touch Points
                            (Digital Directory, Website, Mobile Application..)
                            to be effectively visible for your shoppers and it
                            makes it easy to update them.
                          </p>
                          <p>
                            Based on innovative technology, Mallframe 360°
                            Digital Marketing Ecosystem provides fast, scalable
                            and secure cloud hosting. We believe that, for every
                            second a certain Digital Marketing Tool stalls in
                            loading you automatically will be losing a shopper.
                          </p>

                          <ul>
                            <li>Reliable Cloud Hosting</li>
                            <li>Cost Effective</li>
                            <li>Ultimate Speed Performance</li>
                            <li>Unique security solutions</li>
                            <li>Uptime guarantee of 99.5-99.99%</li>
                            <li>24/7 Customer Service Support</li>
                            <li>Consistent Backups</li>
                          </ul>
                        </>
                      )}

                      {this.props.pageContext.locale == 'en' && (
                        <>
                          <p>
                            Hosting services ensures your Digital Touch Points
                            (Digital Directory, Website, Mobile Application..)
                            to be effectively visible for your shoppers and it
                            makes it easy to update them.
                          </p>
                          <p>
                            Based on innovative technology, Mallframe 360°
                            Digital Marketing Ecosystem provides fast, scalable
                            and secure cloud hosting. We believe that, for every
                            second a certain Digital Marketing Tool stalls in
                            loading you automatically will be losing a shopper.
                          </p>

                          <ul>
                            <li>Reliable Cloud Hosting</li>
                            <li>Cost Effective</li>
                            <li>Ultimate Speed Performance</li>
                            <li>Unique security solutions</li>
                            <li>Uptime guarantee of 99.5-99.99%</li>
                            <li>24/7 Customer Service Support</li>
                            <li>Consistent Backups</li>
                          </ul>
                        </>
                      )}
                    </div>
                  </section>

                  {/*  Support / Technical Maintenance   */}

                  <section className="support-technical text-center">
                    <div className="container ps-relative">
                      <a className="back">
                        <img src={back} />
                        <span>Back</span>
                      </a>

                      <div>
                        <img
                          src={technicalDark}
                          className="support-icon-size"
                        />
                      </div>

                      <h1>Technical Maintenance</h1>
                      {this.props.pageContext.locale == 'tr' && (
                        <>
                          <p>
                            We believe that besides implementing a proper
                            installation, a sustainable operation and accuracy
                            of a running system is vital.
                          </p>

                          <p>
                            In a case where you happen to face an obstacle with
                            hardware or software system, our fully trained
                            Support Line team experts will be there via ‘first
                            priority emergency service calls’ or an ‘on the spot
                            maintenance service’ with offering you a fast and
                            reliable solution.
                          </p>

                          <ul>
                            <li>Accurate and Sustainable Operational System</li>
                            <li>24/7 Customer Service Support</li>
                            <li>On the Spot Maintenance Service</li>
                          </ul>
                        </>
                      )}

                      {this.props.pageContext.locale == 'en' && (
                        <>
                          <p>
                            We believe that besides implementing a proper
                            installation, a sustainable operation and accuracy
                            of a running system is vital.
                          </p>

                          <p>
                            In a case where you happen to face an obstacle with
                            hardware or software system, our fully trained
                            Support Line team experts will be there via ‘first
                            priority emergency service calls’ or an ‘on the spot
                            maintenance service’ with offering you a fast and
                            reliable solution.
                          </p>

                          <ul>
                            <li>Accurate and Sustainable Operational System</li>
                            <li>24/7 Customer Service Support</li>
                            <li>On the Spot Maintenance Service</li>
                          </ul>
                        </>
                      )}
                    </div>
                  </section>

                  {/*  Support / Reporting   */}

                  <section className="support-reporting text-center">
                    <div className="container ps-relative">
                      <a className="back">
                        <img src={back} />
                        <span>Back</span>
                      </a>

                      <div>
                        <img
                          src={reportingDark}
                          className="support-icon-size"
                        />
                      </div>

                      <h1>Reporting</h1>
                      {this.props.pageContext.locale == 'tr' && (
                        <>
                          <p>
                            The most crucial part of working with Digital
                            Marketing Tools are to be able to visualize and
                            understand the process. In order to optimize your
                            advertising structure and campaigns, you need to see
                            what is working the best for your business.{' '}
                          </p>

                          <p>
                            If you want to properly analyze your Digital
                            Marketing investments, you need to start from
                            analyzing the out coming data. Reporting Systems are
                            specifically designed to provide you the most
                            efficient and accurate data.
                          </p>

                          <ul>
                            <li>Clear and Valuable Reporting System</li>
                            <li>Added Value for your Shopping Center</li>
                            <li>Weekly Updates</li>
                            <li>Provides a deep insight on Shopper Behavior</li>
                          </ul>
                        </>
                      )}

                      {this.props.pageContext.locale == 'en' && (
                        <>
                          <p>
                            The most crucial part of working with Digital
                            Marketing Tools are to be able to visualize and
                            understand the process. In order to optimize your
                            advertising structure and campaigns, you need to see
                            what is working the best for your business.{' '}
                          </p>

                          <p>
                            If you want to properly analyze your Digital
                            Marketing investments, you need to start from
                            analyzing the out coming data. Reporting Systems are
                            specifically designed to provide you the most
                            efficient and accurate data.
                          </p>

                          <ul>
                            <li>Clear and Valuable Reporting System</li>
                            <li>Added Value for your Shopping Center</li>
                            <li>Weekly Updates</li>
                            <li>Provides a deep insight on Shopper Behavior</li>
                          </ul>
                        </>
                      )}
                    </div>
                  </section>

                  {/*  Support / Monitoring   */}

                  <section className="support-monitoring text-center">
                    <div className="container ps-relative">
                      <a className="back">
                        <img src={back} />
                        <span>Back</span>
                      </a>

                      <div>
                        <img
                          src={monitoringDark}
                          className="support-icon-size"
                        />
                      </div>

                      <h1>Monitoring</h1>
                      {this.props.pageContext.locale == 'tr' && (
                        <>
                          <p>
                            Monitoring is the most important part of a
                            successful and continuous process for each Digital
                            Touch Point. Monitoring provides consistency to
                            manage a Digital Touch Point in order to ensure that
                            it works properly and runs a high performance.
                          </p>
                          <p>
                            Monitoring is initiated at the beginning of the
                            implementation of the Digital Marketing Tool. This
                            process allows a pro-active tracking condition for
                            the tool and allows corrective actions to be taken
                            if needed.
                          </p>

                          <ul>
                            <li>
                              24/7 Monitoring for Digital Marketing Touch Points
                            </li>
                            <li>Pro-active tracking system</li>
                            <li>24/7 Customer Service Support</li>
                          </ul>
                        </>
                      )}

                      {this.props.pageContext.locale == 'en' && (
                        <>
                          <p>
                            Monitoring is the most important part of a
                            successful and continuous process for each Digital
                            Touch Point. Monitoring provides consistency to
                            manage a Digital Touch Point in order to ensure that
                            it works properly and runs a high performance.
                          </p>
                          <p>
                            Monitoring is initiated at the beginning of the
                            implementation of the Digital Marketing Tool. This
                            process allows a pro-active tracking condition for
                            the tool and allows corrective actions to be taken
                            if needed.
                          </p>

                          <ul>
                            <li>
                              24/7 Monitoring for Digital Marketing Touch Points
                            </li>
                            <li>Pro-active tracking system</li>
                            <li>24/7 Customer Service Support</li>
                          </ul>
                        </>
                      )}
                    </div>
                  </section>

                  {/*  Support / Software Update   */}

                  <section className="support-update text-center">
                    <div className="container ps-relative">
                      <a className="back">
                        <img src={back} />
                        <span>Back</span>
                      </a>

                      <div>
                        <img src={softwareDark} className="support-icon-size" />
                      </div>

                      <h1>Software Update</h1>
                      {this.props.pageContext.locale == 'tr' && (
                        <>
                          <p>
                            Keeping the software running efficiently, depends on
                            updating the systems as when required. We install
                            the latest versions of software programs on the
                            Digital Touch Points.
                          </p>
                          <p>
                            Nonetheless, software firms such as Microsoft,
                            Google and Apple occasionally releases operating
                            system updates in order to make bug fixes and
                            product enhancements. In order to keep the apps
                            running efficiently with the new version of the
                            operating system, updates should be made by our side
                            properly.{' '}
                          </p>

                          <ul>
                            <li>Latest Version of Software Programs</li>
                            <li>Efficiently Running Update System</li>
                            <li>24/7 Customer Service Support</li>
                          </ul>
                        </>
                      )}

                      {this.props.pageContext.locale == 'en' && (
                        <>
                          <p>
                            Keeping the software running efficiently, depends on
                            updating the systems as when required. We install
                            the latest versions of software programs on the
                            Digital Touch Points.
                          </p>
                          <p>
                            Nonetheless, software firms such as Microsoft,
                            Google and Apple occasionally releases operating
                            system updates in order to make bug fixes and
                            product enhancements. In order to keep the apps
                            running efficiently with the new version of the
                            operating system, updates should be made by our side
                            properly.{' '}
                          </p>

                          <ul>
                            <li>Latest Version of Software Programs</li>
                            <li>Efficiently Running Update System</li>
                            <li>24/7 Customer Service Support</li>
                          </ul>
                        </>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </body>
      </Layout>
    )
  }
}
SupportPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
export default SupportPage
